import { createGlobalStyle } from 'styled-components/macro'
import { normalize } from 'polished'

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  :root {
    font-size: 10px;
  }

  #hovercard {
    position: absolute;
    top: 0;
    z-index: 9999;
  }

  #tooltip {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    z-index: 999999999;
  }

  .grecaptcha-badge { 
    visibility: hidden;
  }

  html {
    background: ${props => props.theme.colors.background};
    font-weight: 300;
    font-family: "proxima-nova","Verdana","Gill Sans","Lucida Grande","Arial","Helvetica",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    font-weight: 400;
    
    * {
      box-sizing: border-box;
    }
  }

  body {
    font-size: 1.8rem;
    color: ${props => props.theme.colors.greyDark};
    width: 100%;
  }

  #banner {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 9999;
      width: 100%;
  }

  h1 {
    font-weight: 900;
    font-size: 3.6rem;
    line-height: 4.3rem;
    color: ${props => props.theme.colors.greyDarker};
    margin: 0;
    padding: 0;
  }

  h3 {
    font-weight: 700;
    font-size: 1.6rem;
    color: ${props => props.theme.colors.greyDark};
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    padding-bottom: 0.8rem;
  }

  h4 {
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${props => props.theme.colors.greyDark};
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    margin: 0;
  }

  p {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding: 0;
    margin: 0;
  }

  a {
    transition: all 150ms ease;
    text-decoration: none;
    outline: none;
    color: ${props => props.theme.colors.primaryBase};
    font-weight: 700;
    display: inline-block;

    &:hover {
        color: ${props => props.theme.colors.primaryDarker};
    }
  }

  img {
    height: auto;
    max-width: 100%;
  }
`

export default GlobalStyle